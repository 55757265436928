import { ShippingMethod, ShippingStatus } from '../shipping/types';

import { CurrencyCode } from '@/modules/currencies/constants';
import {
  PictureFormat,
  PictureFormatV2,
  ThumbnailFormat,
} from '@/modules/product/types';

export type CheckoutProduct = {
  id: number;
  slug: string;
  countryCode: string;
  categoryId?: number;
  price: {
    priceAmount?: string;
    nationalShippingCost?: string;
    internationalShippingCost?: string;
    discountedPriceAmount?: string;
    currencyName: CurrencyCode;
  };
  seller: {
    id: number;
    username: string;
    pictureUrl?: string;
    initials: string;
    verified?: boolean;
    firstName: string;
    lastName: string;
    picture?: PictureFormat[] | ThumbnailFormat[];
    reviewsTotal?: number;
    reviewsRating?: number;
    itemsSold?: number;
    lastSeen?: string;
  };
  pictures: PictureFormat[][] | PictureFormatV2[][] | ThumbnailFormat[][] | [];
  size?: {
    id?: string;
    name?: string;
  };
  description: string;
  brandName?: string;
  productType: string;
};

export enum CheckoutType {
  SingleProduct = 'singleProduct',
  Bag = 'bag',
}

type CheckoutSummaryResponseProducts = Record<
  string,
  CheckoutSummaryResponseProduct
>;

type CheckoutSummaryResponseProduct = {
  shippingMethods: ShippingMethod[];
};

export type CheckoutSummaryResponseBuyer = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
};

export type CheckoutSummaryResponseSeller = {
  isOnVacation?: boolean;
  onVacationUntil?: string;
};

type CheckoutSummaryResponseExtra = {
  products?: CheckoutSummaryResponseProducts;
  buyer?: CheckoutSummaryResponseBuyer;
  seller?: CheckoutSummaryResponseSeller;
};

export enum StockStatus {
  OnSale = 'ON_SALE',
  NotAvailable = 'NOT_AVAILABLE',
}

export enum CheckoutSummaryPaymentProvider {
  PayPal = 'PAYPAL',
  Stripe = 'STRIPE',
  Klarna = 'KLARNA',
}

export enum CheckoutSummaryDefaultPaymentMethodProvider {
  Stripe = 'STRIPE',
}

export enum CheckoutSummarySavedPaymentMethodProvider {
  Stripe = 'STRIPE',
}

export type CheckoutSummaryDefaultPaymentMethod = {
  provider: CheckoutSummaryDefaultPaymentMethodProvider;
  id: string;
  wallet: 'apple_pay' | 'google_pay' | 'card';
  brand?: string;
  last4?: string;
};

export type CheckoutSummarySavedPaymentCard = {
  id: string;
  brand: string;
  last4: string;
};

export type CheckoutSummarySavedPaymentMethod = {
  provider: CheckoutSummarySavedPaymentMethodProvider;
  paymentCards: CheckoutSummarySavedPaymentCard[];
};

export enum TaxType {
  UkImportVat = 'UK_IMPORT_VAT',
  UsMft = 'US_MFT',
  AuGst = 'AU_GST',
  CaMft = 'CA_MFT',
  EuImportVat = 'EU_IMPORT_VAT',
  NewZealandImportGst = 'NEW_ZEALAND_IMPORT_GST',
  NorwayImportLvgVat = 'NORWAY_IMPORT_LVG_VAT',
}

export type CheckoutSummaryTax = {
  amount: string;
  name: TaxType;
};

export type CheckoutSummaryResponse = {
  buyerFeeAmount?: string;
  sellerPreferences: {
    freeShippingInMultipleItems?: boolean;
    multipleItemsShippingPrice: 'default' | 'highest' | 'free_national';
  };
  lines: Array<{
    productId: number;
    variantId?: number;
    available: number;
    productPrice: string;
    undiscountedProductPrice?: string;
    stockStatus: StockStatus;
    shippingStatus?: ShippingStatus;
    shippingPrice: string;
    shippingQuoteValidationErrors?: Array<{
      errorCode: string;
      message?: string;
    }>;
  }>;
  shippingProviderId: string;
  productsPrice: string;
  shippingPrice: string;
  totalPrice: string;
  taxSummary?: {
    totalTaxAmount: string;
    shipToLocation: string;
    taxes: CheckoutSummaryTax[];
  };
  paymentProviders: CheckoutSummaryPaymentProvider[];
  stripePublishableKey?: string;
  stripePlatformCountry?: string;
  stripeAccountCountry?: string;
  extra: CheckoutSummaryResponseExtra;
  savedPaymentMethods: CheckoutSummarySavedPaymentMethod[];
  defaultPaymentMethod?: CheckoutSummaryDefaultPaymentMethod;
};

export type CheckoutSummaryRequestLine = {
  productId: number;
  variantId?: number;
};

export type CheckoutSummaryRequest = {
  lines: CheckoutSummaryRequestLine[];
  sellerId: number;
  currency: string;
  shipToAddressId?: number;
};

export type CompletePurchaseRequest = {
  paymentReturnUrl?: string;
  buyerDeviceUuid?: string;
};

export enum PurchaseStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Repeat = 'REPEAT',
  Retry = 'RETRY',
}

export type CompletePurchaseResponse = {
  purchaseStatus: PurchaseStatus;
  retryPaymentUrl?: string;
};
export enum PaymentSourceProvider {
  Stripe = 'STRIPE',
  PayPal = 'PAYPAL',
}

export enum PaymentSourceType {
  PayPal = 'PAYPAL',
  CreditCard = 'CREDITCARD',
  ApplePay = 'APPLEPAY',
  GooglePay = 'GOOGLEPAY',
  Klarna = 'KLARNA',
}

export type StartPurchaseRequestLine = {
  productId: number;
  variantId?: number;
};

export type StartPurchaseRequest = {
  lines: StartPurchaseRequestLine[];
  shipToAddressId: number;
  sellerId: number;
  currency: string;
  expectTotalPrice: string;
  paymentSource?: {
    provider: PaymentSourceProvider;
    type: PaymentSourceType;
  };
  userAgent?: string;
  buyerDeviceUuid?: string;
  paymentMethodId?: string;
};

export type StartPurchaseResponse = {
  purchaseId: string;
  paymentId: number;
  paymentUrl?: string;
  paymentToken?: string;
  paypal?: {
    paymentToken: string;
    paymentUrl?: string;
  };
  stripe?: {
    paymentIntentId: string;
    clientSecret: string;
  };
  lineIds: number[];
};

export type StartPurchaseErrorResponse = {
  id: string;
  code: number;
  message: string;
};

export type InternalErrorResponse = {
  internalErrorCode: number;
  internalErrorMessage: string;
};

export type GetPurchaseCountsResponse = {
  bought: number;
  sold: number;
};

export enum ATPaymentProvider {
  PayPal = 'PAYPAL',
  Stripe = 'STRIPE',
}

export enum ATCheckoutSummaryViewBuyerPaymentMethod {
  PayPal = 'PAYPAL',
  Stripe = 'STRIPE',
  ApplePay = 'APPLEPAY',
  GooglePay = 'GOOGLEPAY',
  Klarna = 'KLARNA',
}

export enum ATStartPaymentActionPaymentType {
  PayPal = 'PAYPAL',
  CreditCard = 'CREDITCARD',
  ApplePay = 'APPLEPAY',
  GooglePay = 'GOOGLEPAY',
  Klarna = 'KLARNA',
}

export enum ATPurchaseConfirmationViewPaymentMethod {
  NewCard = 'STRIPE NEW CARD',
  SavedCard = 'STRIPE SAVED CARD',
  PayPal = 'PAYPAL',
  ApplePay = 'APPLEPAY',
  GooglePay = 'GOOGLEPAY',
  Klarna = 'KLARNA',
}

export type GaEcommerceItemData = {
  id: string;
  name: string;
  sku: string;
  category?: number;
  price: number;
  quantity: number;
};

export type GaEcommerceTransactionData = {
  id: string;
  affiliation: string;
  value: number;
  shipping: number;
  currency: CurrencyCode;
};

// This is not strictly a domain concept but instead an app-level type
export enum PaymentTypeProvider {
  Stripe = 'Stripe',
  PayPal = 'PayPal',
}

// This is not strictly a domain concept but instead an app-level type
export enum PaymentTypeValue {
  PayPal = 'PayPal',
  NewCard = 'NewCard',
  ApplePay = 'ApplePay',
  GooglePay = 'GooglePay',
  Klarna = 'Klarna',
}

// This is not strictly a domain concept but instead an app-level type
export type PaymentType = {
  value: PaymentTypeValue | string;
  provider: PaymentTypeProvider;
  brand?: string;
  last4?: string;
  isSavedCard?: boolean;
  isWallet?: boolean;
  isNewCard?: boolean;
};

// This is not strictly a domain concept but instead an app-level type
export type NewPaymentTypeErrors = {
  cardNumber?: string | null;
  cardExpiry?: string | null;
  cardCvc?: string | null;
  cardPostalCode?: string | null;
};

// This is not strictly a domain concept but instead an app-level type
export type SavedPaymentTypeErrors = {
  cardCvc: string | null | undefined;
};

export type Ga4Item = {
  affiliation?: string;
  item_id: string;
  item_name: string;
  price: number;
  quantity: number;
};

export type Ga4AddToCart = {
  currency: CurrencyCode;
  items: Ga4Item[];
  value: number;
};

export type Ga4Purchase = {
  currency: CurrencyCode;
  items: Ga4Item[];
  shipping: number;
  transaction_id: string;
  value: number;
};

export type RakutenItem = {
  quantity: number;
  unitPrice: number;
  sku: string;
  productName: string;
};

export type PaymentProvidersResponse = {
  klarna: {
    available: boolean;
    priceConstraints?: {
      boundsCurrency: string;
      lowerBound: string;
      higherBound: string;
    };
  };
  stripe: {
    publishableKey?: string;
  };
};

export enum CountriesWithDropdownStates {
  US = 'US',
  IT = 'IT',
}

export type ShippingAddressSelectValue = {
  label: string;
  value: string;
};
