import Decimal from 'decimal.js';
import { CanMakePaymentResult, PaymentRequestOptions } from '@stripe/stripe-js';

import {
  getNearestSizePictureFormat,
  productPictureToPictureFormat,
  productVideoToPictureFormat,
} from '../pictures/helpers';
import { ProductPageProduct, ThumbnailFormat } from '../product/types';

import {
  getPurchaseIdFromStorage,
  removePurchaseIdFromStorage,
  removeCheckoutSummaryFromStorage,
  removePaymentTypeFromStorage,
} from './purchaseHelpers';
import {
  CheckoutProduct,
  CheckoutSummaryDefaultPaymentMethod,
  CheckoutSummaryPaymentProvider,
  CheckoutSummaryRequest,
  CheckoutSummaryRequestLine,
  CheckoutSummaryResponse,
  CheckoutSummarySavedPaymentMethod,
  CheckoutType,
  GaEcommerceItemData,
  GaEcommerceTransactionData,
  PaymentType,
  PaymentTypeProvider,
  PaymentTypeValue,
  TaxType,
} from './types';
import { CHECKOUT_PRODUCTS_SESSION_STORAGE_KEY } from './constants';

import {
  setSessionStorageItem,
  removeSessionStorageItem,
  getSessionStorageItem,
} from '@/modules/storage';
import { getNameVariations } from '@/modules/shop/helpers/getNameVariations';
import { getLogger } from '@/modules/observability/logging';
import { safeJsonParse } from '@/modules/storage/helpers';

export function getDiscountedItemsCount(products: CheckoutProduct[]): number {
  const discountedItems = products.filter((product) =>
    Boolean(product.price.discountedPriceAmount)
  );

  return discountedItems.length;
}

export function createCheckoutSummaryRequestBody(
  products: CheckoutProduct[],
  shipToAddressId?: number
): CheckoutSummaryRequest {
  return {
    shipToAddressId,
    lines: products.map(({ id, size }) => {
      const line: CheckoutSummaryRequestLine = {
        productId: id,
      };

      if (size?.id) {
        line.variantId = Number(size?.id);
      }

      return line;
    }),
    sellerId: products[0].seller.id,
    currency: products[0].price?.currencyName || '',
  };
}

export function createGaEcommerceEventPayload(
  products: CheckoutProduct[],
  checkoutSummary: CheckoutSummaryResponse,
  productsDictionary: Record<number, CheckoutProduct>
): {
  items: GaEcommerceItemData[];
  transaction: GaEcommerceTransactionData;
} | null {
  const purchaseId = getPurchaseIdFromStorage();

  if (!purchaseId) {
    return null;
  }

  return {
    items: checkoutSummary.lines.map((line) => {
      const product = productsDictionary[line.productId];
      return {
        id: purchaseId, // needs to be purchase id, not product id
        name: product?.slug,
        sku: line.variantId
          ? `${line.productId}-${line.variantId}`
          : line.productId.toString(),
        category: product?.categoryId,
        price: parseFloat(line.productPrice),
        quantity: 1,
      };
    }),
    transaction: {
      id: purchaseId,
      affiliation: products[0].seller.username,
      currency: products[0].price.currencyName,
      value: parseFloat(checkoutSummary.totalPrice),
      shipping: parseFloat(checkoutSummary.shippingPrice),
    },
  };
}

export function getStripeLocale(location: string, language: string) {
  switch (language) {
    case 'en':
      if (location === 'nz') {
        return 'en-NZ';
      }
      if (location === 'ca') {
        return 'en-CA';
      }
      if (location === 'us') {
        return 'en';
      }
      if (location === 'ie') {
        return 'auto';
      }
      return 'en-GB';
    case 'fr':
      return 'fr';
    case 'de':
      return 'de';
    case 'it':
      return 'it';
    default:
      return 'auto';
  }
}

export function formatOrderSummaryPrice(price: string) {
  const [wholeValue, decimalAmount] = price.toString().split('.');

  if (!decimalAmount || decimalAmount === '00') {
    return wholeValue;
  }

  return Number(price).toFixed(2);
}

type OrderSummaryProductPriceRow = {
  id: number;
  description: string;
  imageUrl: string;
  price: string;
  undiscountedPrice: string;
  size: string;
  slug: string;
};

export function createOrderSummaryProductPriceRows(
  products: CheckoutProduct[],
  checkoutSummary?: CheckoutSummaryResponse
) {
  if (!checkoutSummary?.lines) {
    return [];
  }

  return products
    .map((product) => {
      const checkoutProduct = checkoutSummary.lines.find(
        (line) => product.id === line.productId
      );

      if (!checkoutProduct) {
        return;
      }

      const { description, id, pictures, size, slug } = product;
      return {
        id,
        description,
        imageUrl: getNearestSizePictureFormat(pictures[0], 72)?.url,
        price: formatOrderSummaryPrice(checkoutProduct.productPrice),
        undiscountedPrice:
          checkoutProduct?.undiscountedProductPrice &&
          formatOrderSummaryPrice(checkoutProduct.undiscountedProductPrice),
        size: size?.name,
        slug,
      };
    })
    .filter((row): row is OrderSummaryProductPriceRow => !!row);
}

/**
 * Returns an array of payment types in the following order:
 * default payment method (if any), wallets, saved cards, paypal, new card
 */
export function sortAvailablePaymentTypes({
  paymentProviders,
  savedPaymentMethods,
  defaultPaymentMethod,
  canMakePaymentResult = {},
}: {
  paymentProviders: CheckoutSummaryPaymentProvider[];
  savedPaymentMethods: CheckoutSummarySavedPaymentMethod[];
  defaultPaymentMethod?: CheckoutSummaryDefaultPaymentMethod;
  canMakePaymentResult?: CanMakePaymentResult;
}): PaymentType[] {
  let availablePaymentTypes: PaymentType[] = [];
  const { applePay: applePayAvailable, googlePay: googlePayAvailable } =
    canMakePaymentResult;
  const isStripeEnabledSeller = paymentProviders.includes(
    CheckoutSummaryPaymentProvider.Stripe
  );
  const isKlarnaEnabledSeller = paymentProviders.includes(
    CheckoutSummaryPaymentProvider.Klarna
  );
  const applePayPaymentType: PaymentType = {
    value: PaymentTypeValue.ApplePay,
    provider: PaymentTypeProvider.Stripe,
    isWallet: true,
  };
  const googlePayPaymentType: PaymentType = {
    value: PaymentTypeValue.GooglePay,
    provider: PaymentTypeProvider.Stripe,
    isWallet: true,
  };
  const klarnaPaymentType: PaymentType = {
    value: PaymentTypeValue.Klarna,
    provider: PaymentTypeProvider.Stripe,
  };

  if (isStripeEnabledSeller) {
    if (applePayAvailable) {
      // Append Apple Pay to available payment types
      availablePaymentTypes = [...availablePaymentTypes, applePayPaymentType];
    } else if (googlePayAvailable) {
      // Append Google Pay to available payment types
      availablePaymentTypes = [...availablePaymentTypes, googlePayPaymentType];
    }

    if (isKlarnaEnabledSeller) {
      availablePaymentTypes = [...availablePaymentTypes, klarnaPaymentType];
    }

    // Append saved cards to available payment types
    savedPaymentMethods.forEach(({ paymentCards }) => {
      availablePaymentTypes = [
        ...availablePaymentTypes,
        ...paymentCards.map((paymentCard) => ({
          value: paymentCard.id,
          provider: PaymentTypeProvider.Stripe,
          brand: paymentCard.brand,
          last4: paymentCard.last4,
          isSavedCard: true,
        })),
      ];
    });
  }

  if (paymentProviders.includes(CheckoutSummaryPaymentProvider.PayPal)) {
    // Append PayPal to available payment types
    availablePaymentTypes = [
      ...availablePaymentTypes,
      { value: PaymentTypeValue.PayPal, provider: PaymentTypeProvider.PayPal },
    ];
  }

  if (isStripeEnabledSeller) {
    // Append New Card to available payment types
    availablePaymentTypes = [
      ...availablePaymentTypes,
      {
        value: PaymentTypeValue.NewCard,
        provider: PaymentTypeProvider.Stripe,
        isNewCard: true,
      },
    ];

    if (defaultPaymentMethod && defaultPaymentMethod.wallet) {
      switch (defaultPaymentMethod.wallet) {
        case 'card':
          // Prepend card default payment method to available payment types
          availablePaymentTypes = [
            {
              value: defaultPaymentMethod.id,
              provider: PaymentTypeProvider.Stripe,
              brand: defaultPaymentMethod?.brand,
              last4: defaultPaymentMethod?.last4,
              isSavedCard: true,
            },
            ...availablePaymentTypes.filter(
              (type) => type.value !== defaultPaymentMethod.id
            ),
          ];
          break;
        case 'apple_pay':
          if (applePayAvailable) {
            // Prepend Apple Pay payment method to available payment types
            availablePaymentTypes = [
              applePayPaymentType,
              ...availablePaymentTypes.filter(
                (type) => type.value !== PaymentTypeValue.ApplePay
              ),
            ];
          }
          break;
        case 'google_pay':
          if (googlePayAvailable) {
            // Prepend Google Pay payment method to available payment types
            availablePaymentTypes = [
              googlePayPaymentType,
              ...availablePaymentTypes.filter(
                (type) => type.value !== PaymentTypeValue.GooglePay
              ),
            ];
          }
          break;
      }
    }
  }

  return availablePaymentTypes;
}

export function getInitialPaymentType({
  paymentTypes,
  defaultPaymentMethod,
}: {
  paymentTypes: PaymentType[];
  defaultPaymentMethod?: CheckoutSummaryDefaultPaymentMethod;
}) {
  const paymentTypesMap = new Map(
    paymentTypes.map((type) => [type.value, type])
  );
  const savedCardPaymentType = paymentTypes.find((type) => type.isSavedCard);

  // Check for last used payment method first
  if (defaultPaymentMethod && defaultPaymentMethod.wallet) {
    const { wallet } = defaultPaymentMethod;

    if (wallet === 'card' && savedCardPaymentType) {
      return savedCardPaymentType;
    }
    if (wallet === 'apple_pay') {
      const applePayType = paymentTypesMap.get(PaymentTypeValue.ApplePay);
      if (applePayType) {
        return applePayType;
      }
    }
    if (wallet === 'google_pay') {
      const googlePayType = paymentTypesMap.get(PaymentTypeValue.GooglePay);
      if (googlePayType) {
        return googlePayType;
      }
    }
  }

  // Fallback to other available payment types in order
  const fallbackOrder = [
    PaymentTypeValue.ApplePay,
    PaymentTypeValue.GooglePay,
    savedCardPaymentType?.value,
    PaymentTypeValue.NewCard,
    PaymentTypeValue.Klarna,
    PaymentTypeValue.PayPal,
  ];

  for (const typeValue of fallbackOrder) {
    if (typeValue && paymentTypesMap.has(typeValue)) {
      return paymentTypesMap.get(typeValue);
    }
  }

  getLogger().info(
    'Missing initial payment type scenario on Checkout Landing page',
    { paymentTypes }
  );

  // Fallback to first payment type. This should never happen.
  return paymentTypes[0];
}

export function priceToPence(price = '') {
  return new Decimal(price).times(100).toNumber();
}

const STRIPE_LABEL_FALLBACK = 'Total';

export function createStripePaymentRequestOptions(
  checkoutSummary?: CheckoutSummaryResponse,
  firstProduct?: CheckoutProduct,
  label?: string
): Pick<PaymentRequestOptions, 'country' | 'currency' | 'total'> {
  return {
    country: checkoutSummary?.stripePlatformCountry || '',
    currency: firstProduct?.price.currencyName?.toLowerCase() || '',
    total: {
      label: label ?? STRIPE_LABEL_FALLBACK,
      amount: priceToPence(checkoutSummary?.totalPrice),
    },
  };
}

export function transformUserProductToCheckoutProduct(
  product: ProductPageProduct,
  slug: string,
  size?: {
    id?: string;
    name?: string;
  }
): CheckoutProduct {
  return {
    id: product.id,
    countryCode: product.country,
    categoryId: product.category,
    pictures: product.videos?.length
      ? product.videos.map(productVideoToPictureFormat)
      : product.pictures?.map(productPictureToPictureFormat) || [],
    description: product.description,
    brandName: product.attributes.brand?.name,
    productType: product.attributes.product_type || '',
    price: {
      nationalShippingCost: product.pricing.national_shipping_cost?.total_price,
      internationalShippingCost:
        product.pricing.international_shipping_cost?.total_price,
      discountedPriceAmount: product.pricing.discounted_price?.total_price,
      currencyName: product.pricing.currency_name,
      priceAmount: product.pricing.original_price.total_price,
    },
    seller: {
      id: product.seller.id,
      username: product.seller.username,
      picture:
        product.seller.picture &&
        Object.keys(product.seller.picture).reduce(
          (acc: ThumbnailFormat[], key) => {
            const picture = product.seller.picture?.[key];
            if (picture) {
              acc.push({
                url: picture,
                height: Number(key),
                width: Number(key),
              });
            }
            return acc;
          },
          []
        ),
      initials: product.seller.initials,
      verified: product.seller.verified,
      firstName: product.seller.first_name,
      lastName: product.seller.last_name,
      reviewsTotal: product.seller_reviews.reviews_total,
      reviewsRating: product.seller_reviews.reviews_rating,
      itemsSold: product.seller_activity.items_sold,
      lastSeen: product.seller_activity.last_seen,
    },
    slug,
    size,
  };
}

export function addCheckoutProducts({
  products,
  type,
}: {
  products: CheckoutProduct[];
  type: CheckoutType;
}) {
  return setSessionStorageItem(
    CHECKOUT_PRODUCTS_SESSION_STORAGE_KEY,
    JSON.stringify({
      type,
      products,
    })
  );
}

export function getCheckoutProducts(): CheckoutProduct[] | undefined {
  const checkoutProducts = getSessionStorageItem(
    CHECKOUT_PRODUCTS_SESSION_STORAGE_KEY
  );

  return safeJsonParse(checkoutProducts, {}).products;
}

export function getCheckoutType(): CheckoutType | undefined {
  const checkoutType = getSessionStorageItem(
    CHECKOUT_PRODUCTS_SESSION_STORAGE_KEY
  );

  return safeJsonParse(checkoutType, {}).type;
}

export function clearCheckoutProducts() {
  removeSessionStorageItem(CHECKOUT_PRODUCTS_SESSION_STORAGE_KEY);
}

export function destroyCheckoutSession() {
  clearCheckoutProducts();
  removePurchaseIdFromStorage();
  removeCheckoutSummaryFromStorage();
  removePaymentTypeFromStorage();
}

export function getDisplayItems({
  checkoutSummary,
  shippingLabel,
  taxLabel,
}: {
  checkoutSummary?: CheckoutSummaryResponse;
  shippingLabel: string;
  taxLabel: string | null;
}) {
  const shippingDisplayItem = {
    label: shippingLabel,
    amount: priceToPence(checkoutSummary?.shippingPrice),
  };
  const addTax = taxLabel && checkoutSummary?.taxSummary?.totalTaxAmount;
  return addTax
    ? [
        shippingDisplayItem,
        {
          label: taxLabel,
          amount: priceToPence(checkoutSummary?.taxSummary?.totalTaxAmount),
        },
      ]
    : [shippingDisplayItem];
}

/**
 * We should only use this helper as a fallback when the product prices come back as £0.00
 * from the Checkout Summary API response (e.g. when the seller cannot ship to the buyer's shipping address),
 * and we want to display placeholder values. In this scenario, the buyer should be unable to purchase the item.
 */
export function getProductsSubtotal(products: CheckoutProduct[]) {
  const subtotal = products.reduce((total, product) => {
    const price = product.price.priceAmount || 0;
    const discountedPrice = product.price.discountedPriceAmount || 0;

    if (Number(discountedPrice) > 0) {
      return total.plus(new Decimal(discountedPrice));
    }
    return total.plus(new Decimal(price));
  }, new Decimal(0));

  return subtotal.toFixed(2);
}

export function getSellerDetails(product: CheckoutProduct) {
  return {
    id: product.seller.id,
    username: product.seller.username,
    initials: product.seller.initials,
    verified: product.seller.verified,
    itemsSold: product.seller.itemsSold,
    lastActive: product.seller.lastSeen,
    name: getNameVariations(product.seller.firstName, product.seller.lastName)
      .fullName,
    pictureUrl: getNearestSizePictureFormat(product.seller.picture, 54)?.url,
    feedbackTotal: product.seller.reviewsTotal || 0,
    feedbackRating: product.seller.reviewsRating || 0,
  };
}

export function calculateShowTaxSummary(
  checkoutSummary?: CheckoutSummaryResponse
) {
  if (!checkoutSummary) {
    return;
  }

  const taxName = checkoutSummary.taxSummary?.taxes[0].name.toUpperCase();

  const shouldShowTaxSummary =
    taxName &&
    Boolean(checkoutSummary.taxSummary?.totalTaxAmount) &&
    Object.values(TaxType).includes(taxName as TaxType);

  return shouldShowTaxSummary;
}
