export const UNAUTHED_ROUTES = [
  {
    path: /^\/login\/(?!magic-link\/).*/, // Capture all paths that start with /login/, but not if it contains /magic-link/
  },
  {
    path: /^\/signup\/(?!magic-link\/).*/, // Capture all paths that start with /signup/, but not if it contains /magic-link/
  },
  {
    path: /^\/magic-link\/.*/, // Capture all paths that begin with /magic-link/
  },
  {
    path: /password-reset/,
  },
];
