export function safeJsonParse<T>(
  jsonString?: string | null,
  fallback: T | null = null
) {
  if (!jsonString) {
    return fallback;
  }

  try {
    // eslint-disable-next-line
    return JSON.parse(jsonString);
  } catch {
    return fallback;
  }
}
