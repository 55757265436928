export const CHECKOUT_LANDING_PATH = '/pay';
export const CHECKOUT_PRODUCTS_SESSION_STORAGE_KEY = 'checkout_products';
export const PURCHASE_ID_SESSION_STORAGE_KEY = 'checkout_purchase_id';
export const CHECKOUT_EVENT_JOURNEY_STORAGE_KEY = 'checkout_event_journey';
export const SHIPPING_ADDRESS_STORAGE_KEY = 'checkout_shipping_address';
export const CHECKOUT_SUMMARY_STORAGE_KEY = 'checkout_checkout_summary';
export const PAYMENT_TYPE_STORAGE_KEY = 'checkout_payment_type';

export enum CheckoutRoutes {
  Pay = '/pay/',
  Cancel = '/pay/cancel/',
  Failure = '/pay/failure/',
  Return = '/pay/return/',
  Success = '/pay/success/',
}

export const ROUTES_WITH_CHECKOUT_HEADER = Object.values(CheckoutRoutes).filter(
  (route) => route !== CheckoutRoutes.Success
) as CheckoutRoutes[];

export const PURCHASE_BUTTON_HEIGHT = '45px';

export const US_STATES = [
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'Armed Forces Americas', code: 'AA' },
  { name: 'Armed Forces', code: 'AE' },
  { name: 'Armed Forces Pacific', code: 'AP' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'District Of Columbia (Washington, D.C.)', code: 'DC' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
  { name: 'Guam', code: 'GU' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Virgin Islands', code: 'VI' },
];

export const IT_STATES = [
  { name: 'Agrigento', code: 'AG' },
  { name: 'Alessandria', code: 'AL' },
  { name: 'Ancona', code: 'AN' },
  { name: 'Aosta', code: 'AO' },
  { name: 'Arezzo', code: 'AR' },
  { name: 'Ascoli Piceno', code: 'AP' },
  { name: 'Asti', code: 'AT' },
  { name: 'Avellino', code: 'AV' },
  { name: 'Bari', code: 'BA' },
  { name: 'Barletta-Andria-Trani', code: 'BT' },
  { name: 'Belluno', code: 'BL' },
  { name: 'Benevento', code: 'BN' },
  { name: 'Bergamo', code: 'BG' },
  { name: 'Biella', code: 'BI' },
  { name: 'Bologna', code: 'BO' },
  { name: 'Bolzano', code: 'BZ' },
  { name: 'Brescia', code: 'BS' },
  { name: 'Brindisi', code: 'BR' },
  { name: 'Cagliari', code: 'CA' },
  { name: 'Caltanissetta', code: 'CL' },
  { name: 'Campobasso', code: 'CB' },
  { name: 'Carbonia-Iglesias', code: 'CI' },
  { name: 'Caserta', code: 'CE' },
  { name: 'Catania', code: 'CT' },
  { name: 'Catanzaro', code: 'CZ' },
  { name: 'Chieti', code: 'CH' },
  { name: 'Como', code: 'CO' },
  { name: 'Cosenza', code: 'CS' },
  { name: 'Cremona', code: 'CR' },
  { name: 'Crotone', code: 'KR' },
  { name: 'Cuneo', code: 'CN' },
  { name: 'Enna', code: 'EN' },
  { name: 'Fermo', code: 'FM' },
  { name: 'Ferrara', code: 'FE' },
  { name: 'Firenze', code: 'FI' },
  { name: 'Foggia', code: 'FG' },
  { name: 'Forlì-Cesena', code: 'FC' },
  { name: 'Frosinone', code: 'FR' },
  { name: 'Genova', code: 'GE' },
  { name: 'Gorizia', code: 'GO' },
  { name: 'Grosseto', code: 'GR' },
  { name: 'Imperia', code: 'IM' },
  { name: 'Isernia', code: 'IS' },
  { name: `L'Aquila`, code: 'AQ' },
  { name: 'La Spezia', code: 'SP' },
  { name: 'Latina', code: 'LT' },
  { name: 'Lecce', code: 'LE' },
  { name: 'Lecco', code: 'LC' },
  { name: 'Livorno', code: 'LI' },
  { name: 'Lodi', code: 'LO' },
  { name: 'Lucca', code: 'LU' },
  { name: 'Macerata', code: 'MC' },
  { name: 'Mantova', code: 'MN' },
  { name: 'Massa-Carrara', code: 'MS' },
  { name: 'Matera', code: 'MT' },
  { name: 'Medio Campidano', code: 'VS' },
  { name: 'Messina', code: 'ME' },
  { name: 'Milano', code: 'MI' },
  { name: 'Modena', code: 'MO' },
  { name: 'Monza e della Brianza', code: 'MB' },
  { name: 'Napoli', code: 'NA' },
  { name: 'Novara', code: 'NO' },
  { name: 'Nuoro', code: 'NU' },
  { name: 'Ogliastra', code: 'OG' },
  { name: 'Olbia-Tempio', code: 'OT' },
  { name: 'Oristano', code: 'OR' },
  { name: 'Padova', code: 'PD' },
  { name: 'Palermo', code: 'PA' },
  { name: 'Parma', code: 'PR' },
  { name: 'Pavia', code: 'PV' },
  { name: 'Perugia', code: 'PG' },
  { name: 'Pesaro e Urbino', code: 'PU' },
  { name: 'Pescara', code: 'PE' },
  { name: 'Piacenza', code: 'PC' },
  { name: 'Pisa', code: 'PI' },
  { name: 'Pistoia', code: 'PT' },
  { name: 'Pordenone', code: 'PN' },
  { name: 'Potenza', code: 'PZ' },
  { name: 'Prato', code: 'PO' },
  { name: 'Ragusa', code: 'RG' },
  { name: 'Ravenna', code: 'RA' },
  { name: 'Reggio Calabria', code: 'RC' },
  { name: 'Reggio Emilia', code: 'RE' },
  { name: 'Rieti', code: 'RI' },
  { name: 'Rimini', code: 'RN' },
  { name: 'Roma', code: 'RM' },
  { name: 'Rovigo', code: 'RO' },
  { name: 'Salerno', code: 'SA' },
  { name: 'Sassari', code: 'SS' },
  { name: 'Savona', code: 'SV' },
  { name: 'Siena', code: 'SI' },
  { name: 'Siracusa', code: 'SR' },
  { name: 'Sondrio', code: 'SO' },
  { name: 'Taranto', code: 'TA' },
  { name: 'Teramo', code: 'TE' },
  { name: 'Terni', code: 'TR' },
  { name: 'Torino', code: 'TO' },
  { name: 'Trapani', code: 'TP' },
  { name: 'Trento', code: 'TN' },
  { name: 'Treviso', code: 'TV' },
  { name: 'Trieste', code: 'TS' },
  { name: 'Udine', code: 'UD' },
  { name: 'Varese', code: 'VA' },
  { name: 'Venezia', code: 'VE' },
  { name: 'Verbano-Cusio-Ossola', code: 'VB' },
  { name: 'Vercelli', code: 'VC' },
  { name: 'Verona', code: 'VR' },
  { name: 'Vibo Valentia', code: 'VV' },
  { name: 'Vicenza', code: 'VI' },
  { name: 'Viterbo', code: 'VT' },
];
