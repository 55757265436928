import {
  setSessionStorageItem,
  removeSessionStorageItem,
  getSessionStorageItem,
} from '../storage';

import {
  PURCHASE_ID_SESSION_STORAGE_KEY,
  CHECKOUT_SUMMARY_STORAGE_KEY,
  PAYMENT_TYPE_STORAGE_KEY,
} from './constants';
import {
  CheckoutSummaryResponse,
  PaymentType,
  PaymentTypeProvider,
  PaymentTypeValue,
  PaymentSourceProvider,
  PaymentSourceType,
} from './types';

import { safeJsonParse } from '@/modules/storage/helpers';

export function savePurchaseId(purchaseId: string) {
  return setSessionStorageItem(PURCHASE_ID_SESSION_STORAGE_KEY, purchaseId);
}

export function removePurchaseIdFromStorage() {
  return removeSessionStorageItem(PURCHASE_ID_SESSION_STORAGE_KEY);
}

export function getPurchaseIdFromStorage() {
  return getSessionStorageItem(PURCHASE_ID_SESSION_STORAGE_KEY);
}

export function saveCheckoutSummaryToStorage(
  checkoutSummary: CheckoutSummaryResponse
) {
  return setSessionStorageItem(
    CHECKOUT_SUMMARY_STORAGE_KEY,
    JSON.stringify(checkoutSummary)
  );
}

export function removeCheckoutSummaryFromStorage() {
  return removeSessionStorageItem(CHECKOUT_SUMMARY_STORAGE_KEY);
}

export function getCheckoutSummaryFromStorage(): CheckoutSummaryResponse | null {
  const checkoutSummary = getSessionStorageItem(CHECKOUT_SUMMARY_STORAGE_KEY);

  return safeJsonParse(checkoutSummary, null);
}

export function savePaymentTypeToStorage(paymentType: PaymentType) {
  return setSessionStorageItem(
    PAYMENT_TYPE_STORAGE_KEY,
    JSON.stringify(paymentType)
  );
}

export function removePaymentTypeFromStorage() {
  return removeSessionStorageItem(PAYMENT_TYPE_STORAGE_KEY);
}

export function getPaymentTypeFromStorage(): PaymentType | null {
  const paymentType = getSessionStorageItem(PAYMENT_TYPE_STORAGE_KEY);
  return safeJsonParse(paymentType, null);
}

export function getPaymentSource(paymentType: PaymentType) {
  switch (paymentType.provider) {
    case PaymentTypeProvider.Stripe:
      switch (paymentType.value) {
        case PaymentTypeValue.ApplePay:
          return {
            provider: PaymentSourceProvider.Stripe,
            type: PaymentSourceType.ApplePay,
          };
        case PaymentTypeValue.GooglePay:
          return {
            provider: PaymentSourceProvider.Stripe,
            type: PaymentSourceType.GooglePay,
          };
        case PaymentTypeValue.Klarna:
          return {
            provider: PaymentSourceProvider.Stripe,
            type: PaymentSourceType.Klarna,
          };
        default:
          return {
            provider: PaymentSourceProvider.Stripe,
            type: PaymentSourceType.CreditCard,
          };
      }
    case PaymentTypeProvider.PayPal:
      return {
        provider: PaymentSourceProvider.PayPal,
        type: PaymentSourceType.PayPal,
      };
    default:
      return null;
  }
}

export function calculateFormStatus({
  formPreviouslySubmitted,
  isFormFieldsValid,
  shippingValid,
  sellerOnVacation,
  isErrorVisible,
  isLoading,
}: {
  formPreviouslySubmitted: boolean;
  isFormFieldsValid: boolean;
  shippingValid: boolean;
  sellerOnVacation: boolean;
  isErrorVisible: boolean;
  isLoading: boolean;
}) {
  const isPageLoad =
    !formPreviouslySubmitted &&
    !isFormFieldsValid &&
    !shippingValid &&
    !isErrorVisible &&
    !isLoading;

  const isFieldsValidShippingInvalidNotSubmitted =
    !formPreviouslySubmitted &&
    isFormFieldsValid &&
    !shippingValid &&
    !isErrorVisible &&
    !isLoading;

  const isFieldsValidShippingValidNotSubmitted =
    !formPreviouslySubmitted &&
    isFormFieldsValid &&
    shippingValid &&
    !isErrorVisible &&
    !isLoading;

  const isFieldsInvalidShippingInvalidAndSubmitted =
    formPreviouslySubmitted &&
    !isFormFieldsValid &&
    !shippingValid &&
    !isErrorVisible &&
    !isLoading;

  const isFieldsValidShippingInvalidAndSubmitted =
    formPreviouslySubmitted &&
    isFormFieldsValid &&
    !shippingValid &&
    !isErrorVisible &&
    !isLoading;

  const isFieldsValidShippingValidAndSubmitted =
    formPreviouslySubmitted &&
    isFormFieldsValid &&
    shippingValid &&
    !isErrorVisible &&
    !isLoading;

  const isFieldsValidShippingValidAndSubmittedAndLoading =
    formPreviouslySubmitted &&
    isFormFieldsValid &&
    shippingValid &&
    !isErrorVisible &&
    isLoading;

  const isFieldsValidAndShippingErrorVisible =
    formPreviouslySubmitted &&
    isFormFieldsValid &&
    !shippingValid &&
    isErrorVisible &&
    !isLoading;

  const isFieldsInvalidAndShippingErrorVisible =
    formPreviouslySubmitted &&
    !isFormFieldsValid &&
    !shippingValid &&
    isErrorVisible &&
    !isLoading;

  switch (true) {
    case sellerOnVacation:
      return {
        canSubmit: false,
        isFormValid: true,
      };
    case isPageLoad:
      return {
        canSubmit: true,
        isFormValid: false,
      };
    case isFieldsInvalidShippingInvalidAndSubmitted:
    case isFieldsInvalidAndShippingErrorVisible:
      return {
        canSubmit: false,
        isFormValid: false,
      };
    case isFieldsValidShippingValidAndSubmittedAndLoading:
    case isFieldsValidAndShippingErrorVisible:
      return {
        canSubmit: false,
        isFormValid: true,
      };
    case isFieldsValidShippingInvalidAndSubmitted:
    case isFieldsValidShippingValidAndSubmitted:
    case isFieldsValidShippingValidNotSubmitted:
    case isFieldsValidShippingInvalidNotSubmitted:
      return {
        canSubmit: true,
        isFormValid: true,
      };
    default:
      return {
        canSubmit: !formPreviouslySubmitted || isFormFieldsValid,
        isFormValid: isFormFieldsValid,
      };
  }
}
