'use client';

import { createContext } from 'react';

type UserLocation = {
  userLocation: string;
  defaultLocation: string;
  localisedRoutes: string[];
  supportedLocations: string[];
};

const LocationContext = createContext<UserLocation>({
  userLocation: 'us',
  defaultLocation: 'us',
  localisedRoutes: ['brands'],
  supportedLocations: [],
});

export { LocationContext };
