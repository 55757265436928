import { PROTECTED_ROUTES } from '@/modules/auth/protectedRoutes';
import { UNAUTHED_ROUTES } from '@/modules/auth/unauthedRoutes';

export function replaceUsernameInPath(path: string) {
  if (path.includes('isMine')) {
    const hrefWithUsername = path.replace('undefined', '[username]');
    [path] = hrefWithUsername.split('?isMine');
  }
  return path;
}

export function isProtectedRoute(route: string) {
  return PROTECTED_ROUTES.find(({ path }) => {
    return path.test(route);
  });
}

export function isUnauthedRoute(route: string) {
  const currentPath = new URL(route).pathname;
  return UNAUTHED_ROUTES.find(({ path }) => {
    return path.test(currentPath);
  });
}

export function ensureTrailingSlash(pathname: string) {
  // If the pathname is an external URL e.g. to zendesk, return as is
  if (pathname.startsWith('http') || pathname.startsWith('depop:')) {
    return pathname;
  }

  const url = new URL(pathname, 'https://depop.com/');

  if (!url.pathname.endsWith('/')) {
    url.pathname += '/';
  }

  return url.pathname + url.search;
}
