import production from '@/modules/routing/manifests/production.json';
import staging from '@/modules/routing/manifests/staging.json';
import local from '@/modules/routing/manifests/local.json';

export function getRoutesManifest() {
  if (process.env.NEXT_PUBLIC_DEPLOY_ENV === 'development') {
    return local;
  }

  if (process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production') {
    return production;
  }

  return staging;
}

export function isRouteMigrated(path: string) {
  const migratedRoutes = getRoutesManifest();
  const [pathWithoutQueryParams] = path.split('?');

  return Boolean(
    migratedRoutes.routes.find((route) => pathWithoutQueryParams.match(route))
  );
}
