/**
 * @TODO TN 5/10/23 - make context messages translation keys
 */
export const PROTECTED_ROUTES = [
  {
    path: /\/auth-example\/?$/,
    titleKey: 'exampleLoginTitleKey', //Must match a translation key in the relavent translation set
  },
  {
    path: /^\/\w+\/likes\/\?isMine\=true/,
  },
  {
    path: /^\/pay\//,
  },
  {
    path: /\/sellinghub\/\w+/,
  },
  {
    path: /^\/\w+\/saved/,
  },
  {
    path: /\/start-selling\/\w+/,
  },
  {
    path: /^\/outfits\/\w+/,
  },
  {
    path: /^\/stories\/moderate\/\w+/,
  },
  {
    path: /^\/messages\/offers\//,
    titleKey: 'OfferLoginExpTitle',
  },
  {
    path: /^\/messages\//,
  },
  {
    path: /^\/settings\//,
  },
];
