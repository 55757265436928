import { BREAKPOINTS } from '@depop/web-ui-kit/theme/default/breakpoints.constants';

import {
  ListProduct,
  PictureFormat,
  Preview,
  ProductPicture,
  ProductVideo,
  ThumbnailFormat,
} from '../product/types';

/*
  This function will always try to return an image that
  is at least equal in size to the target width. If there isn't it will
  then return the nearest size, even if it's smaller.
*/
export function getNearestSizePictureFormat(
  pictureFormats: ThumbnailFormat[] | undefined,
  width: number
) {
  if (!pictureFormats?.length) {
    return undefined;
  }

  function nearestSize(a: ThumbnailFormat, b: ThumbnailFormat) {
    return Math.abs(b.width - width) < Math.abs(a.width - width) ? b : a;
  }

  const filtered = pictureFormats.filter((a) => a.width >= width);
  if (filtered.length) {
    return filtered.reduce(nearestSize);
  }
  return pictureFormats.reduce(nearestSize);
}

export function filterRequiredPictures(
  pictures: Partial<PictureFormat>[]
): PictureFormat[];
export function filterRequiredPictures(
  pictures: Partial<ThumbnailFormat>[]
): ThumbnailFormat[];
/* eslint-disable @typescript-eslint/no-explicit-any */
export function filterRequiredPictures(pictures: Partial<any>[] = []): any[] {
  return pictures.reduce<ThumbnailFormat[] | PictureFormat[]>(
    (acc, picture) => {
      const { url, height, width, id } = picture;
      if (height && url && width) {
        if (id) {
          return [...acc, { id, url, height, width }];
        }
        return [...acc, { url, height, width }];
      }
      return acc;
    },
    []
  );
}

function createSrcSet(preview: Preview) {
  return Object.entries(preview)
    .map(([width, url]) => `${url} ${width}w`)
    .join(', ');
}

function getSmallestPreviewImage(preview: Preview) {
  /*
      In the future P10 images will be returned from the BE.
      They do actually exist though and so for now we just
      want to experiment with them
    */
  const fullSizeImageUrl = preview['1280'];
  if (!fullSizeImageUrl) {
    return;
  }

  const path = fullSizeImageUrl.substring(0, fullSizeImageUrl.lastIndexOf('/'));
  return path ? `${path}/P10.jpg` : undefined;
}

export function buildProductImageProps({
  preview,
  sellerUserName,
}: Pick<ListProduct, 'preview' | 'sellerUserName'>) {
  return {
    srcSet: createSrcSet(preview),
    blurImageSrc: getSmallestPreviewImage(preview),
    width: 245,
    height: 245,
    src: preview['1280'],
    sizes: `
      (min-width: ${BREAKPOINTS.SM_MIN_PX}) 245px,
      (min-width: ${BREAKPOINTS.LG_MIN_PX}) 220px,
      150px`,
    ...(!!sellerUserName
      ? { alt: `item listed by ${sellerUserName}` }
      : { alt: '' }),
  };
}

export function picturePreviewAdapter(picture: ProductPicture): Preview {
  return Object.values(picture.formats).reduce((prev, format) => {
    return { ...prev, [format.width]: format.url };
  }, {});
}

export function videoPreviewAdapter(video: ProductVideo): Preview {
  return Object.values(video.thumbnail.formats).reduce((prev, format) => {
    return { ...prev, [format.width]: format.url };
  }, {});
}

export function productPictureToPictureFormat(
  picture: ProductPicture
): PictureFormat[] {
  return Object.values(picture.formats).map((format) => ({
    id: picture.id,
    url: format.url,
    width: format.width,
    height: format.height,
  }));
}

export function productVideoToPictureFormat(
  video: ProductVideo
): PictureFormat[] {
  return Object.values(video.thumbnail.formats).map((format) => ({
    id: Number(video.id),
    url: format.url,
    width: format.width,
    height: format.height,
  }));
}
